import { CoreMenu } from "@core/types";

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  {
    id: "admin",
    type: "section",
    title: "Admin Control",
    translate: "MENU.APPS.USER.COLLAPSIBLE",
    icon: "",
    children: [
   
      {
        id: "view-roles",
        title: "Roles",
        translate: "MENU.APPS.USER.LIST",
        type: "item",
        icon: "check-square",
        url: "apps/role/role-list",
      },
      {
        id: "view-admins",
        title: "Admins",
        translate: "MENU.APPS.USER.LIST",
        type: "item",
        icon: "user",
        url: "apps/admin/admin-list",
      },
      {
        id: "view-statistics",
        title: "statistics",
        translate: "MENU.APPS.USER.VIEW",
        type: "item",
        icon: "pie-chart",
        url: "apps/statistics/statistics-list",
      },
      {
        id: "view-banners",
        title: "Banners",
        translate: "MENU.APPS.USER.LIST",
        type: "item",
        icon: "server",
        url: "apps/banner/banner-list",
      },
      // {
      //   id: "edit",
      //   title: "Edit",
      //   translate: "MENU.APPS.USER.EDIT",
      //   type: "item",
      //   icon: "circle",
      //   url: "apps/user/user-edit",
      // },
    ],
  },
  {
    id: "users",
    type: "section",
    title: "Users",
    translate: "MENU.APPS.USER.COLLAPSIBLE",
    icon: "user",
    children: [
      {
        id: "view-users",
        title: "Trainee",
        translate: "MENU.APPS.USER.LIST",
        type: "item",
        icon: "user",
        url: "apps/user/user-list",
      },
      {
        id: "view-coaches",
        title: "Coaches",
        translate: "MENU.APPS.USER.LIST",
        type: "item",
        icon: "user",
        url: "apps/coach/coaches-list",
      },
      // {
      //   id: "view",
      //   title: "View",
      //   translate: "MENU.APPS.USER.VIEW",
      //   type: "item",
      //   icon: "circle",
      //   url: "apps/user/user-view",
      // },
      // {
      //   id: "edit",
      //   title: "Edit",
      //   translate: "MENU.APPS.USER.EDIT",
      //   type: "item",
      //   icon: "circle",
      //   url: "apps/user/user-edit",
      // },
    ],
  },
  {
    id: "exercises",
    type: "section",
    title: "Exercises",
    translate: "MENU.APPS.USER.COLLAPSIBLE",
    icon: "user",
    children: [
      {
        id: "view-exercise_categories",
        title: "Exercise Categories",
        translate: "Exercise Categories",
        type: "item",
        icon: "category",
        url: "apps/category/category-list",
      },
      {
        id: "view-exercises",
        title: "Exercises",
        translate: "Exercises",
        type: "item",
        icon: "excercise",
        url: "apps/exercise/exercise-list",
      },
    
    ],
  },
  {
    id: "nutrition",
    type: "section",
    title: "Nutritions",
    translate: "MENU.APPS.USER.COLLAPSIBLE",
    icon: "user",
    children: [
      {
        id: "view-product_categories",
        title: "Nutrition Categories",
        translate: "Nutrition Categories",
        type: "item",
        icon: "category",
        url: "apps/nutrition-category/nutrition-category-list",
      },
      {
        id: "view-products",
        title: "Nutrition",
        translate: "Nutrition",
        type: "item",
        icon: "nutrition",
        url: "apps/nutrition/nutrition-list",
      },
    
    ],
  },
  {
    id: "subscriptions",
    type: "section",
    title: "Subscription",
    translate: "MENU.APPS.USER.COLLAPSIBLE",
    icon: "user",
    children: [
      {
        id: "view-plans",
        title: "Plans",
        translate: "Plans",
        type: "item",
        icon: "dollar-sign",
        url: "apps/subscription/subscription-list",
      },
      {
        id: "view-coupons",
        title: "Coupons",
        translate: "Coupons",
        type: "item",
        icon: "dollar-sign",
        url: "apps/coupons/coupons-list",
      },
    ],
  },
];
